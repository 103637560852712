@import './_lightbox';
//@import './_katex';
@import './_flippy.scss';

html,
body {
  height: 100%;
  margin: 0;
}

a:link,
a:visited,
a:focus {
  /* Shoo, ugly link colors! */
  color: inherit;
  /* Begone, dotted outlines! */
  outline: 0;
}

/* Makes code blocks wrap lines. */
code, pre {
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}

/* Extra classes not found in Tachyons. */
.all-animate,
.all-animate:hover,
.all-animate:focus {
  transition: all .15s ease-in-out;
}

.filter-grayscale {
  filter: grayscale(1);
}

.hover-nofilter:hover,
.hover-nofilter:focus {
  filter: none;
}

#ipynb {
  blockquote { @extend .bl; }
  blockquote { @extend .bw2; }
  blockquote { @extend .pl4; }
  blockquote { @extend .pr4; }
  blockquote { @extend .ml0; }
  blockquote { @extend .mr0; }
  blockquote { @extend .bg-flippy-90; }

  .ipynb-code { @extend .bl; }
  .ipynb-code { @extend .bw2; }
  .ipynb-code { @extend .pl4; }
  .ipynb-code { @extend .b--flippy-standout; }

  .ipynb-stdout { @extend .bl; }
  .ipynb-stdout { @extend .bw2; }
  .ipynb-stdout { @extend .pl4; }
  .ipynb-stdout { @extend .b--flippy-standout-2; }
}


/* Tufte CSS experiments */
/*.sidenote,
.marginnote {
  float: right;
  clear: right;
  margin-right: -60%;
  width: 50%;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 0.8rem;
  line-height: 1.3;
  vertical-align: baseline;
  position: relative;
}

#article {
  counter-reset: sidenote-counter;
  div {
    p {
      max-width: 75%;
    }
  }
}

.sidenote-number:after {
  counter-increment: sidenote-counter;
  content: counter(sidenote-counter);
}

.sidenote:before {
  content: counter(sidenote-counter) " ";
}

.sidenote-number:after,
.sidenote:before {
    //font-family: et-book-roman-old-style;
    position: relative;
    vertical-align: baseline;
}

.sidenote-number:after {
    content: counter(sidenote-counter);
    font-size: 0.7rem;
    top: -0.5rem;
    left: 0.1rem;
}

*/

/*
html {
  width: 100%;
  height: 100%;
}

body {
  min-width: 100%;
  min-height: 100%;

  display: grid;
  grid-template-rows: 4em 1.5em 1fr auto;
  grid-template-columns: 5em auto 5em;
  grid-template-areas: "tl tt tr" "nl nav-menu nr" "cl contents cr" "footer footer footer";

  font-family: 'helvetica neue', helvetica, sans-serif;
  background-color: #fffff8;
  color: #111111;
}

#nav-menu {
  grid-area: nav-menu;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 1em;

  text-align: center;

  a {
    color: inherit;
    text-decoration: inherit;
  }

  button {
    border: none;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    background-color: inherit;
    color: inherit;
    text-decoration: inherit;
  }
}

#contents {
  grid-area: contents;

  min-width: 100%;
  min-height: 100%;
}

footer {
  grid-area: footer;

  text-align: center;
  background-color: #efefef;
}
*/

/* -- ARTICLES -- */
/*
#article-container {
  display: grid;
  grid-template-rows: 7em 1fr 7em;
  grid-template-columns: auto auto auto;
  grid-template-areas: "tl tt tr" "al article ar" "bl bb br";

  #article {
    grid-area: article;

    text-align: justify;
    max-width: 800px;
    margin: auto;

    .highlight {
      div { border-radius: 1.5%; }
      pre { padding: 2%; }
    }

  }
}
*/

/* -- GALLERY PAGES -- */
/*
#gallery-container {
  display: grid;

  grid-template-rows: 7em 1fr 7em;
  grid-template-columns: 5em 1fr 5em;
  grid-template-areas: "tl tt tr" "gl gallery gr" "bl bb br";

  #gallery {
    grid-area: gallery;

    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(20, 1fr);
    grid-gap: 1em;
    grid-auto-flow: row;

    .gallery-element {
      margin: auto;
    }
  }
}


/* Other stuff */
/*
a:link {
  color: #551A8B;
}

a:visited {
  color: #0000EE;
}
*/

