/*
   NEGATIVE MARGINS

   Base:
     n = negative

   Modifiers:
     a = all
     t = top
     r = right
     b = bottom
     l = left

     1 = 1st step in spacing scale
     2 = 2nd step in spacing scale
     3 = 3rd step in spacing scale
     4 = 4th step in spacing scale
     5 = 5th step in spacing scale
     6 = 6th step in spacing scale
     7 = 7th step in spacing scale

   Media Query Extensions:
     -ns = not-small
     -m  = medium
     -l  = large

*/



.na1 { margin: calc(-1 * var(--spacing-extra-small)); }
.na2 { margin: calc(-1 * var(--spacing-small)); }
.na3 { margin: calc(-1 * var(--spacing-medium)); }
.na4 { margin: calc(-1 * var(--spacing-large)); }
.na5 { margin: calc(-1 * var(--spacing-extra-large)); }
.na6 { margin: calc(-1 * var(--spacing-extra-extra-large)); }
.na7 { margin: calc(-1 * var(--spacing-extra-extra-extra-large)); }

.nl1 { margin-left: calc(-1 * var(--spacing-extra-small)); }
.nl2 { margin-left: calc(-1 * var(--spacing-small)); }
.nl3 { margin-left: calc(-1 * var(--spacing-medium)); }
.nl4 { margin-left: calc(-1 * var(--spacing-large)); }
.nl5 { margin-left: calc(-1 * var(--spacing-extra-large)); }
.nl6 { margin-left: calc(-1 * var(--spacing-extra-extra-large)); }
.nl7 { margin-left: calc(-1 * var(--spacing-extra-extra-extra-large)); }

.nr1 { margin-right: calc(-1 * var(--spacing-extra-small)); }
.nr2 { margin-right: calc(-1 * var(--spacing-small)); }
.nr3 { margin-right: calc(-1 * var(--spacing-medium)); }
.nr4 { margin-right: calc(-1 * var(--spacing-large)); }
.nr5 { margin-right: calc(-1 * var(--spacing-extra-large)); }
.nr6 { margin-right: calc(-1 * var(--spacing-extra-extra-large)); }
.nr7 { margin-right: calc(-1 * var(--spacing-extra-extra-extra-large)); }

.nb1 { margin-bottom: calc(-1 * var(--spacing-extra-small)); }
.nb2 { margin-bottom: calc(-1 * var(--spacing-small)); }
.nb3 { margin-bottom: calc(-1 * var(--spacing-medium)); }
.nb4 { margin-bottom: calc(-1 * var(--spacing-large)); }
.nb5 { margin-bottom: calc(-1 * var(--spacing-extra-large)); }
.nb6 { margin-bottom: calc(-1 * var(--spacing-extra-extra-large)); }
.nb7 { margin-bottom: calc(-1 * var(--spacing-extra-extra-extra-large)); }

.nt1 { margin-top: calc(-1 * var(--spacing-extra-small)); }
.nt2 { margin-top: calc(-1 * var(--spacing-small)); }
.nt3 { margin-top: calc(-1 * var(--spacing-medium)); }
.nt4 { margin-top: calc(-1 * var(--spacing-large)); }
.nt5 { margin-top: calc(-1 * var(--spacing-extra-large)); }
.nt6 { margin-top: calc(-1 * var(--spacing-extra-extra-large)); }
.nt7 { margin-top: calc(-1 * var(--spacing-extra-extra-extra-large)); }

@media (--breakpoint-not-small) {

  .na1-ns { margin: calc(-1 * var(--spacing-extra-small)); }
  .na2-ns { margin: calc(-1 * var(--spacing-small)); }
  .na3-ns { margin: calc(-1 * var(--spacing-medium)); }
  .na4-ns { margin: calc(-1 * var(--spacing-large)); }
  .na5-ns { margin: calc(-1 * var(--spacing-extra-large)); }
  .na6-ns { margin: calc(-1 * var(--spacing-extra-extra-large)); }
  .na7-ns { margin: calc(-1 * var(--spacing-extra-extra-extra-large)); }

  .nl1-ns { margin-left: calc(-1 * var(--spacing-extra-small)); }
  .nl2-ns { margin-left: calc(-1 * var(--spacing-small)); }
  .nl3-ns { margin-left: calc(-1 * var(--spacing-medium)); }
  .nl4-ns { margin-left: calc(-1 * var(--spacing-large)); }
  .nl5-ns { margin-left: calc(-1 * var(--spacing-extra-large)); }
  .nl6-ns { margin-left: calc(-1 * var(--spacing-extra-extra-large)); }
  .nl7-ns { margin-left: calc(-1 * var(--spacing-extra-extra-extra-large)); }

  .nr1-ns { margin-right: calc(-1 * var(--spacing-extra-small)); }
  .nr2-ns { margin-right: calc(-1 * var(--spacing-small)); }
  .nr3-ns { margin-right: calc(-1 * var(--spacing-medium)); }
  .nr4-ns { margin-right: calc(-1 * var(--spacing-large)); }
  .nr5-ns { margin-right: calc(-1 * var(--spacing-extra-large)); }
  .nr6-ns { margin-right: calc(-1 * var(--spacing-extra-extra-large)); }
  .nr7-ns { margin-right: calc(-1 * var(--spacing-extra-extra-extra-large)); }

  .nb1-ns { margin-bottom: calc(-1 * var(--spacing-extra-small)); }
  .nb2-ns { margin-bottom: calc(-1 * var(--spacing-small)); }
  .nb3-ns { margin-bottom: calc(-1 * var(--spacing-medium)); }
  .nb4-ns { margin-bottom: calc(-1 * var(--spacing-large)); }
  .nb5-ns { margin-bottom: calc(-1 * var(--spacing-extra-large)); }
  .nb6-ns { margin-bottom: calc(-1 * var(--spacing-extra-extra-large)); }
  .nb7-ns { margin-bottom: calc(-1 * var(--spacing-extra-extra-extra-large)); }

  .nt1-ns { margin-top: calc(-1 * var(--spacing-extra-small)); }
  .nt2-ns { margin-top: calc(-1 * var(--spacing-small)); }
  .nt3-ns { margin-top: calc(-1 * var(--spacing-medium)); }
  .nt4-ns { margin-top: calc(-1 * var(--spacing-large)); }
  .nt5-ns { margin-top: calc(-1 * var(--spacing-extra-large)); }
  .nt6-ns { margin-top: calc(-1 * var(--spacing-extra-extra-large)); }
  .nt7-ns { margin-top: calc(-1 * var(--spacing-extra-extra-extra-large)); }

}

@media (--breakpoint-medium) {
  .na1-m { margin: calc(-1 * var(--spacing-extra-small)); }
  .na2-m { margin: calc(-1 * var(--spacing-small)); }
  .na3-m { margin: calc(-1 * var(--spacing-medium)); }
  .na4-m { margin: calc(-1 * var(--spacing-large)); }
  .na5-m { margin: calc(-1 * var(--spacing-extra-large)); }
  .na6-m { margin: calc(-1 * var(--spacing-extra-extra-large)); }
  .na7-m { margin: calc(-1 * var(--spacing-extra-extra-extra-large)); }

  .nl1-m { margin-left: calc(-1 * var(--spacing-extra-small)); }
  .nl2-m { margin-left: calc(-1 * var(--spacing-small)); }
  .nl3-m { margin-left: calc(-1 * var(--spacing-medium)); }
  .nl4-m { margin-left: calc(-1 * var(--spacing-large)); }
  .nl5-m { margin-left: calc(-1 * var(--spacing-extra-large)); }
  .nl6-m { margin-left: calc(-1 * var(--spacing-extra-extra-large)); }
  .nl7-m { margin-left: calc(-1 * var(--spacing-extra-extra-extra-large)); }

  .nr1-m { margin-right: calc(-1 * var(--spacing-extra-small)); }
  .nr2-m { margin-right: calc(-1 * var(--spacing-small)); }
  .nr3-m { margin-right: calc(-1 * var(--spacing-medium)); }
  .nr4-m { margin-right: calc(-1 * var(--spacing-large)); }
  .nr5-m { margin-right: calc(-1 * var(--spacing-extra-large)); }
  .nr6-m { margin-right: calc(-1 * var(--spacing-extra-extra-large)); }
  .nr7-m { margin-right: calc(-1 * var(--spacing-extra-extra-extra-large)); }

  .nb1-m { margin-bottom: calc(-1 * var(--spacing-extra-small)); }
  .nb2-m { margin-bottom: calc(-1 * var(--spacing-small)); }
  .nb3-m { margin-bottom: calc(-1 * var(--spacing-medium)); }
  .nb4-m { margin-bottom: calc(-1 * var(--spacing-large)); }
  .nb5-m { margin-bottom: calc(-1 * var(--spacing-extra-large)); }
  .nb6-m { margin-bottom: calc(-1 * var(--spacing-extra-extra-large)); }
  .nb7-m { margin-bottom: calc(-1 * var(--spacing-extra-extra-extra-large)); }

  .nt1-m { margin-top: calc(-1 * var(--spacing-extra-small)); }
  .nt2-m { margin-top: calc(-1 * var(--spacing-small)); }
  .nt3-m { margin-top: calc(-1 * var(--spacing-medium)); }
  .nt4-m { margin-top: calc(-1 * var(--spacing-large)); }
  .nt5-m { margin-top: calc(-1 * var(--spacing-extra-large)); }
  .nt6-m { margin-top: calc(-1 * var(--spacing-extra-extra-large)); }
  .nt7-m { margin-top: calc(-1 * var(--spacing-extra-extra-extra-large)); }

}

@media (--breakpoint-large) {
  .na1-l { margin: calc(-1 * var(--spacing-extra-small)); }
  .na2-l { margin: calc(-1 * var(--spacing-small)); }
  .na3-l { margin: calc(-1 * var(--spacing-medium)); }
  .na4-l { margin: calc(-1 * var(--spacing-large)); }
  .na5-l { margin: calc(-1 * var(--spacing-extra-large)); }
  .na6-l { margin: calc(-1 * var(--spacing-extra-extra-large)); }
  .na7-l { margin: calc(-1 * var(--spacing-extra-extra-extra-large)); }

  .nl1-l { margin-left: calc(-1 * var(--spacing-extra-small)); }
  .nl2-l { margin-left: calc(-1 * var(--spacing-small)); }
  .nl3-l { margin-left: calc(-1 * var(--spacing-medium)); }
  .nl4-l { margin-left: calc(-1 * var(--spacing-large)); }
  .nl5-l { margin-left: calc(-1 * var(--spacing-extra-large)); }
  .nl6-l { margin-left: calc(-1 * var(--spacing-extra-extra-large)); }
  .nl7-l { margin-left: calc(-1 * var(--spacing-extra-extra-extra-large)); }

  .nr1-l { margin-right: calc(-1 * var(--spacing-extra-small)); }
  .nr2-l { margin-right: calc(-1 * var(--spacing-small)); }
  .nr3-l { margin-right: calc(-1 * var(--spacing-medium)); }
  .nr4-l { margin-right: calc(-1 * var(--spacing-large)); }
  .nr5-l { margin-right: calc(-1 * var(--spacing-extra-large)); }
  .nr6-l { margin-right: calc(-1 * var(--spacing-extra-extra-large)); }
  .nr7-l { margin-right: calc(-1 * var(--spacing-extra-extra-extra-large)); }

  .nb1-l { margin-bottom: calc(-1 * var(--spacing-extra-small)); }
  .nb2-l { margin-bottom: calc(-1 * var(--spacing-small)); }
  .nb3-l { margin-bottom: calc(-1 * var(--spacing-medium)); }
  .nb4-l { margin-bottom: calc(-1 * var(--spacing-large)); }
  .nb5-l { margin-bottom: calc(-1 * var(--spacing-extra-large)); }
  .nb6-l { margin-bottom: calc(-1 * var(--spacing-extra-extra-large)); }
  .nb7-l { margin-bottom: calc(-1 * var(--spacing-extra-extra-extra-large)); }

  .nt1-l { margin-top: calc(-1 * var(--spacing-extra-small)); }
  .nt2-l { margin-top: calc(-1 * var(--spacing-small)); }
  .nt3-l { margin-top: calc(-1 * var(--spacing-medium)); }
  .nt4-l { margin-top: calc(-1 * var(--spacing-large)); }
  .nt5-l { margin-top: calc(-1 * var(--spacing-extra-large)); }
  .nt6-l { margin-top: calc(-1 * var(--spacing-extra-extra-large)); }
  .nt7-l { margin-top: calc(-1 * var(--spacing-extra-extra-extra-large)); }
}

