@import './tachyons/tachyons';
@import './_vs';
@import './_monokai';

/* Tachyons-style classes for dynamically switching themes. */

/**
 * Generated by Gpick 0.2.6rc1
 *  variations result 3 line 2: #d18f00, rgb(209, 143, 0), hsl(295, 56%, 0%)
 *  variations result 6 line 2: #ffe144, rgb(255, 225, 68), hsl(360, 88%, 27%)
 * : #ffb700, rgb(255, 183, 0), hsl(360, 72%, 0%)
 * : #fffff3, rgb(255, 255, 243), hsl(360, 100%, 95%)
 * : #ffffff, rgb(255, 255, 255), hsl(360, 100%, 100%)
 * black: #000000, rgb(0, 0, 0), hsl(0, 0%, 0%)
 * : #111111, rgb(17, 17, 17), hsl(24, 7%, 7%)
 * : #191200, rgb(25, 18, 0), hsl(35, 7%, 0%)
 * non-green monkai: #272722, rgb(39, 39, 34), hsl(55, 15%, 13%)
 * monokai bg: #272822, rgb(39, 40, 34), hsl(55, 16%, 13%)
 *  variations result 6 line 1: #303030, rgb(48, 48, 48), hsl(68, 19%, 19%)
 *  variations result 2 line 3: #50009a, rgb(80, 0, 154), hsl(113, 0%, 60%)
 * : #9300d9, rgb(147, 0, 217), hsl(208, 0%, 85%)
 * : #c316ec, rgb(195, 22, 236), hsl(275, 9%, 93%)
 * : #e82dfa, rgb(232, 45, 250), hsl(328, 18%, 98%)
 */
html[data-theme="light"] {
  --flippy-bg:	#fffff3;
  --flippy-bg-90:	#f7f7eb;
  --flippy-select-bg: #b3d4fc;
  --flippy-text: #000000;

  /* Starkly contrasting elements. */
  --flippy-standout-bg: var(--gold);
  --flippy-standout-bg-2: green;

  .highlight-flippy {
    @extend %highlight-vs;
  }
}

html[data-theme="dark"] {
  --flippy-bg:	#272722;
  --flippy-bg-90:	#373732;
  --flippy-select-bg: #50009A;
  --flippy-text: #ffffff;

  --flippy-standout-bg: #9300d9;
  --flippy-standout-bg-2: #a6e22e;

  .highlight-flippy {
    @extend %highlight-monokai;
  }

  /* An image which also flips its colors. */
  .img-flippy {
    -webkit-filter: invert(1);
    filter:progid:DXImageTransform.Microsoft.BasicImage(invert='1');
    filter: invert(1);
  }
}

.bg-flippy {
  background-color: var(--flippy-bg);
}

.bg-flippy-90 {
  background-color: var(--flippy-bg-90);
}

/* There is no standalone bg-flippy class because it does not make sense
   without .flippy anyway. */
.flippy {
  color: var(--flippy-text);

  ::selection,
  ::-moz-selection,
  ::-webkit-selection {
    background: var(--flippy-select-bg);
    color: inherit;
  }

  /*a:link {
    text-decoration: none;
    background: -webkit-linear-gradient(var(--flippy-bg), var(--flippy-bg)), -webkit-linear-gradient(var(--flippy-bg), var(--flippy-bg)), -webkit-linear-gradient(currentColor, currentColor);
    background: linear-gradient(var(--flippy-bg), var(--flippy-bg)), linear-gradient(var(--flippy-bg), var(--flippy-bg)), linear-gradient(currentColor, currentColor);
    -webkit-background-size: 0.05em 1px, 0.05em 1px, 1px 1px;
    -moz-background-size: 0.05em 1px, 0.05em 1px, 1px 1px;
    background-size: 0.05em 1px, 0.05em 1px, 1px 1px;
    background-repeat: no-repeat, no-repeat, repeat-x;
    text-shadow: 0.03em 0 var(--flippy-bg), -0.03em 0 var(--flippy-bg), 0 0.03em var(--flippy-bg), 0 -0.03em var(--flippy-bg), 0.06em 0 var(--flippy-bg), -0.06em 0 var(--flippy-bg), 0.09em 0 var(--flippy-bg), -0.09em 0 var(--flippy-bg), 0.12em 0 var(--flippy-bg), -0.12em 0 var(--flippy-bg), 0.15em 0 var(--flippy-bg), -0.15em 0 var(--flippy-bg);
    background-position: 0% 93%, 100% 93%, 0% 93%;
  }*/

  /* Link underline too high with this.
  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    a:link {
      background-position-y: 87%, 87%, 87%;
    }
  }*/

  /*a:link::selection,
  a:link::-moz-selection {
      text-shadow: 0.03em 0 var(--flippy-select-bg), -0.03em 0 var(--flippy-select-bg), 0 0.03em var(--flippy-select-bg), 0 -0.03em var(--flippy-select-bg), 0.06em 0 var(--flippy-select-bg), -0.06em 0 var(--flippy-select-bg), 0.09em 0 var(--flippy-select-bg), -0.09em 0 var(--flippy-select-bg), 0.12em 0 var(--flippy-select-bg), -0.12em 0 var(--flippy-select-bg), 0.15em 0 var(--flippy-select-bg), -0.15em 0 var(--flippy-select-bg);
  }
  */
}

.bg-flippy-standout {
  background-color: var(--flippy-standout-bg);
}

.flippy-standout {
  color: #000000;
}

.b--flippy {
  border-color: var(--flippy-text);
}

.b--flippy-standout {
  border-color: var(--flippy-standout-bg);
}

.b--flippy-standout-2 {
  border-color: var(--flippy-standout-bg-2);
}

.hover-bg-flippy:hover,
.hover-bg-flippy:focus {
  background-color: var(--flippy-text);
  color: var(--flippy-bg);
}
